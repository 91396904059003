@tailwind base;
@tailwind components;
@tailwind utilities;

/* React Quill */
.ql-clipboard {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.ql-snow {
  @apply dark:text-white;
}

.ql-toolbar {
  @apply dark:text-white;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.ql-container {
  height: 100%;
  min-height: 6rem;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

/* Hide scrollbar */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
